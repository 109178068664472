import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from "../components/layout/Layout"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Stack, Typography, Box } from "@mui/material";

export default function CaseStudy({ pageContext }) {
  const { casestudy } = pageContext;

  useEffect(() => {
    function initApollo() {
      var n = Math.random().toString(36).substring(7);
      var o = document.createElement("script");
      o.src = "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" + n;
      o.async = true;
      o.defer = true;
      o.onload = function () {
        window.trackingFunctions.onLoad({ appId: "66312ccf53fe5801c7e5189f" });
      };
      document.head.appendChild(o);
    }
    initApollo();
  }, []);

  return (
    <Layout>
      <Stack sx={{ background: "rgb(215, 233, 255)", padding: { xs: "40px 20px", sm: "60px 40px", md: "60px 90px" }, gap: "6px", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
        <Stack>
          <h1 className="text-3xl  font-[600] text-indigo-600 " style={{ display: "flex", alignItems: "center", gap: "12px", marginBottom: "20px" }}>
            <Link to="#" style={{ display: "flex" }}>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "24px", fontWeight: "bold", marginTop: "3px" }} />
            </Link>{casestudy?.title}
          </h1>
          <h1 className="text-xl sm:text-2xl font-medium text-[#1f2937] mt-2">{casestudy?.text}</h1>
          <Stack alignItems="center" direction="row" gap="10px">
            <h1 className="text-[14px] font-medium mt-7 text-[#1f2937]" style={{
              display: "flex", alignItems: "center", width: "fit-content",
              padding: "10px 25px", borderRadius: "520px", background: "rgb(61 61 61 / 15%)", textTransform: "uppercase"
            }}>
              {casestudy?.type}
            </h1>
            {casestudy?.duration && (
              <span className="text-[16px] font-medium mt-7 text-[#1f2937]"> - Time to launch {casestudy?.duration}</span>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack justifyContent="center" alignItems="center" sx={{ padding: { xs: "20px", sm: "20px 60px", md: "20px 140px", lg: "20px 220px" }, gap: { xs: "30px", md: "50px" } }}>
        {casestudy.about && (
          <Stack gap="10px" width="100%" justifyContent="flex-start">
            <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937]">About the project</h1>
            <div className='text-[16px]' dangerouslySetInnerHTML={{ __html: casestudy.about }} />
          </Stack>
        )}

        {casestudy?.aboutimg && (
          <Box component="img" src={casestudy?.aboutimg} sx={{ width: "80%" }} />
        )}

        {casestudy.problem && (
          <Stack gap="10px" width="100%" justifyContent="flex-start">
            <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937]">Problem</h1>
            <div className='text-[16px]' dangerouslySetInnerHTML={{ __html: casestudy.problem }} />
          </Stack>
        )}

        {casestudy.solution && (
          <Stack gap="10px" width="100%" justifyContent="flex-start">
            <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937]">Solution</h1>
            <div className='text-[16px]' dangerouslySetInnerHTML={{ __html: casestudy.solution }} />
          </Stack>
        )}

        {casestudy.features && (
          <Stack gap="10px" width="100%" justifyContent="flex-start">
            <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937]">Key Features built</h1>
            <div className='text-[16px]' dangerouslySetInnerHTML={{ __html: casestudy.features }} />
          </Stack>
        )}
        {casestudy.additional && (
          <Stack gap="10px" width="100%" justifyContent="flex-start">
            <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937]">Additional Challenges Addressed</h1>
            <div className='text-[16px]' dangerouslySetInnerHTML={{ __html: casestudy.additional }} />
          </Stack>
        )}
        {casestudy?.techimage && (
          <>
            <Stack gap="10px" width="100%" justifyContent="flex-start">
              <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937] justify-start">Technologies used</h1>
            </Stack>
            <Box component="img" src={casestudy?.techimage} sx={{ width: "90%" }} />
          </>
        )}

        {casestudy.outcomes && (
          <Stack gap="10px" width="100%" justifyContent="flex-start" marginBottom="40px">
            <h1 className="text-2xl sm:text-3xl font-medium text-[#1f2937]">Outcomes</h1>
            <div className='text-[16px]' dangerouslySetInnerHTML={{ __html: casestudy.outcomes }} />
          </Stack>
        )}

        {casestudy?.outcomesimg1 && (
          <Stack direction="row" gap="6%" marginBottom="20px">
            <Box component="img" src={casestudy?.outcomesimg1} sx={{ width: "47%" }} />
            <Box component="img" src={casestudy?.outcomesimg2} sx={{ width: "47%" }} />
          </Stack>
        )}

        {casestudy?.testimonial && (
          <Stack width="100%" justifyContent="center" alignItems="center" sx={{ background: "#F8F7F9", padding: "80px 100px", marginBottom: "20px" }}>
            <p className='text-[19px] font-semibold '>
              "{casestudy?.testimonial}"
            </p>
            <p className="mt-5 text-[16px]">Testimony from Startup Founder</p>
          </Stack>
        )}
      </Stack>
      <Stack
      direction="row"
      justifyContent={
        casestudy.previous && casestudy.next ? "space-between" : casestudy.previous ? "flex-start" : "flex-end"
      }
      width="100%"
      sx={{ marginTop: "20px", padding: "0 20px", marginBottom: "20px" }}
    >
        {casestudy.previous && (
          <Link to={`/case-studies/${casestudy.previous}`} style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold" }} />
              <Typography variant="h6" sx={{fontSize:"18px"}}>Previous</Typography>
            </Box>
          </Link>
        )}
        {casestudy.next && (
          <Link to={`/case-studies/${casestudy.next}`} style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <Typography variant="h6" sx={{fontSize:"18px"}}>Next</Typography>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold", transform: 'rotate(180deg)' }} />
            </Box>
          </Link>
        )}
      </Stack>
    </Layout>
  );
}
